import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const PennFertilityMassonLandingPage = () => (
  <PhysicianLandingPage
    physician="Puneet Masson, MD"
    institution="Penn Fertility Care"
    referralCode="MASSON"
    physicianURL="https://www.pennmedicine.org/providers/profile/puneet-masson"
  />
)

export default PennFertilityMassonLandingPage
